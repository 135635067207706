import React from 'react';
import { Tooltip } from 'antd';
import { AsYouType } from 'libphonenumber-js';
import {
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';

import CustomerStatusCell from 'components/table/customerStatusCell';
import CustomerIntegrationsCell from 'components/table/customerIntegrationsCell';
import CustomerNotesCell from 'components/table/customerNotesCell';
import CityFilter from 'components/table/customerCityFilter';
import If from 'components/if';

export const columns = [
  {
    title: 'Customer Name',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    sorter: true,
  },
  {
    title: 'Address',
    key: 'address',
    align: 'left',
    render: ({ address }) => address.description,
    sorter: true,
    filterDropdown: props => <CityFilter {...props} />,
  },
  {
    title: 'Phone',
    dataIndex: 'contact_phone',
    key: 'contact_phone',
    align: 'center',
    sorter: true,
    render: phone => {
      return phone ? new AsYouType('US').input(phone) : '';
    },
  },
  {
    title: 'Active',
    key: 'active',
    sorter: true,
    align: 'center',
    render: customer => <CustomerStatusCell customer={customer} />,
  },
  {
    title: 'Integrations',
    key: 'integrations',
    align: 'center',
    render: customer => <CustomerIntegrationsCell customer={customer} />,
  },
  {
    title: 'Notes',
    align: 'center',
    render: customer => (
      <If condition={customer.notes}>
        <CustomerNotesCell text={customer.notes} />
      </If>
    ),
  },
];

export const tableActions = {
  editCustomer: {
    tooltip: 'Edit Customer',
    type: 'link',
    style: { width: '24px' },
    icon: <EditOutlined style={{ fontSize: '16px' }} />,
  },
  customerDetails: {
    tooltip: 'Review Customer',
    type: 'link',
    style: { width: '24px' },
    icon: <EyeOutlined style={{ fontSize: '16px' }} />,
  },
  customerPolygon: {
    tooltip: 'Customer polygon',
    type: 'link',
    style: { width: '24px' },
    icon: <RadarChartOutlined style={{ fontSize: '16px' }} />,
  },
};

export const modals = {
  updateCustomer: {
    title: 'Customer Details',
    width: '100%',
    height: '100%',
  },
  reviewCustomer: {
    width: '55%',
    showTitle: ({ name }) => `${name} Details`,
  },
  createPolygon: {
    width: '60%',
    showTitle: ({ name }) => `${name} Details`,
  },
};

export const createButton = {
  title: 'Create',
  type: 'primary',
  shape: 'round',
  icon: <PlusOutlined />,
};

export const resetSortButton = {
  type: 'primary',
  title: 'Reset Sort',
  shape: 'round',
};

export const pageSettings = {
  autocomplete: { width: '100%', selectWidth: '20%' },
  notes: { limit: 50 },
};

export const reviewCustomerColumns = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    width: '35%',
    render: text => ({
      props: {
        style: { fontWeight: 600 }
      },
      children: text
    }),
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: '65%',
    align: 'left',
    tableLayout: 'fixed',
    ellipsis: true,
    render: text => (
      <Tooltip title={text}>
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {text || '-'}
        </div>
      </Tooltip>
    ),
  },
];
