import { action, computed, observable } from 'mobx';

const DEFAULT_ADDRESS = { description: '', latitude: '', longitude: '' };

class Customer {
  @observable id = '';
  @observable name = '';
  @observable active = '';
  @observable bringg_integration = '';
  @observable address = DEFAULT_ADDRESS;
  @observable notes = '';
  @observable email = '';
  @observable contact_name = '';
  @observable send_sms = false;

  constructor(customer, rootStore) {
    Object.assign(this, customer);
    this.rootStore = rootStore;
  }

  @action.bound setAddress(value) {
    this.address.description = value;
  }

  @action.bound setLocation({ latitude, longitude }) {
    this.address.latitude = latitude;
    this.address.longitude = longitude;
  }

  @action.bound onStatusChanged(responseData) {
    this.active = responseData.active;
  }

  @action.bound async onStatusChange(status) {
    const { method, url } = this.rootStore.urls.customers.active;

    await this.rootStore.makeRequest(
      this.onStatusChanged,
      method(status),
      url(this.id)
    );
  }

  @computed get addressDescription() {
    return this.address.description;
  }

  @computed get addressLatitude() {
    return this.address.latitude;
  }

  @computed get addressLongitude() {
    return this.address.longitude;
  }
}

export default Customer;
