import React from 'react';
import { inject, observer } from 'mobx-react';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { compose } from 'services/helpers';

const CustomerIntegrationsCell = ({ customer, customersStore }) =>
  <Switch
    onChange={e => customersStore.onIntegrationsStatusChange(customer.id, e)}
    checkedChildren={<CheckOutlined />}
    unCheckedChildren={<CloseOutlined />}
    checked={customer.bringg_integration}
    defaultChecked={customer.bringg_integration}
    disabled={!customer.active}
  />

export default compose(
  inject(({ rootStore }) => ({
    customersStore: rootStore.customersStore,
  })),
  observer
)(CustomerIntegrationsCell);
